import logo from './logo.png';
import logo2 from './logo2.png';
import './App.css';

function App() {
  return (
    <>
      <div className="bg-secondary w-[100vw] min-h-[100vh] p-5 sm:p-10 md:p-20">

        <div className="flex text-black font-semibold text-[1rem]">
          {/* <p>Timp Web</p> */}
          <img 
            src={logo2} 
            alt="Timp Web Logo"
            width={300}
            // width={50}
          />
        </div>
        <div className="flex flex-col justify-center mt-10">
          <h1 className="text-primary font-bold text-[5rem] leading-none sm:leading-normal">Simple, modern websites for all.</h1>
          <h2 className="text-gray text-[1.5rem]">Get a brand new, constantly maintained website, for just <span className="text-primary font-semibold">$199/month</span>.</h2>
        </div>

      </div>

      <div className="rotate-180">
        <svg viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
          {/* <path d="M0,80 C120,200 350,0 500,130 L500,00 L0,0 Z" style={{ stroke: "none", fill: "#0f13ff" }}></path> */}
          <path d="M0,80 C120,200 350,0 500,130 L500,00 L0,0 Z" style={{ stroke: "none", fill: "#1B81C5" }}></path>
        </svg>
      </div>

      <div className="bg-primary w-[100vw] min-h-[75vh] p-5 sm:p-10 md:p-20 pt-0">
        <div className="flex justify-center items-center w-full">
          <div className="w-[700px]">
            <p className="text-secondary text-[2.5rem] text-left sm:text-center font-bold mb-5">What we do.</p>
            <p className="text-secondary text-[1.75rem] text-left sm:text-center font-normal">For a flat monthly fee, we'll design, develop, host, maintain, and upate your static website. Unlimited content update requests and support tickets.</p>
          </div>
        </div>
      </div>

      <div className="bg-secondary w-[100vw] min-h-[50vh] p-5 sm:p-10 md:p-20 pt-0">
        <div className="flex justify-center items-center w-full">
          <div className="w-[700px] flex flex-col justify-center items-center">
            <p className="text-primary text-[2.5rem] text-left sm:text-center font-bold mb-5 w-full">Our Work</p>
            <p className="text-primary text-[1.5rem] text-left sm:text-center font-normal">Right now we're a one-man band that's just getting started, but feel free to check out the existing software engineer's portfolio.</p>
            <button 
              className="bg-primary border-r-10 p-4 text-secondary font-bold mt-10 rounded-md"
              onClick={() => {
                window.open("https://www.toddjohnson.pro", "_blank")
              }}
            >
              View Portfolio
            </button>
          </div>
        </div>
      </div>


      <div className="bg-primary w-[100vw] p-5 sm:p-10 md:p-20 pt-0">
        <div className="flex justify-center items-center w-full">
          <div className="w-[700px] flex flex-col justify-center items-center">
            <p className="text-secondary text-[2.5rem] text-left sm:text-center font-bold mb-5 w-full">Get in touch!</p>
            <p className="text-secondary text-[1.5rem] text-left sm:text-center font-normal">Feel free to reach out to todd@timpweb.com to learn more.</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default App;
